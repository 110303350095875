.smallCard {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border-left-style: solid;
  border-left-width: 13px;
  opacity: 1;
}

.smallCardTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  margin: 0;
}

.smallCardLabel {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .smallCard {
    width: 96%;
    height: 130%;
  }

  .smallCardLeftSideContainer {
    padding-left: 10%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 959px) {
  .smallCard {
    width: 112%;
    height: 130%;
  }

  .smallCardLeftSideContainer {
    padding-left: 11%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 960px) and (max-width: 1023px) {
  .smallCard {
    width: 101%;
    height: 110%;
  }

  .smallCardLeftSideContainer {
    padding-left: 11%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .smallCard {
    width: 101%;
    height: 120%;
  }

  .smallCardLeftSideContainer {
    padding-left: 11%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .smallCard {
    width: 101%;
    height: 130%;
  }

  .smallCardLeftSideContainer {
    padding-left: 11%;
  }
}
