.cardTitleChart {
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .cardTitleChart {
    padding-left: 6%;
    padding-top: 2%;
  }

  .areaChartContainer {
      padding: 3%;
      padding-left: 0;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .cardTitleChart {
    padding-left: 4%;
    padding-top: 1%;
  }

  .areaChartContainer {
      padding: 3%;
      padding-left: 0;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .cardTitleChart {
    padding-left: 6%;
    padding-top: 1%;
  }

  .areaChartContainer {
    padding: 5%;
    padding-left: 2%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .cardTitleChart {
    padding-left: 6%;
    padding-top: 1%;
  }

  .areaChartContainer {
    padding: 5%;
    padding-left: 2%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .cardTitleChart {
    padding-left: 6%;
    padding-top: 1%;
  }

  .areaChartContainer {
    padding: 5%;
    padding-left: 2%;
  }
}
