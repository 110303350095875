.eventCommanderLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventCommanderTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin: 0;
}

.eventCommanderSubtitle {
  font-family: "Lato", sans-serif;
  text-align: left;
  font-size: 14px;
}

.eventCommanderTime {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.poweredByEventtiaFooter {
  display: flex;
}

.poweredByEventtiaFooterLabel {
  width: 100%;
  text-align: center;
}

.poweredByFooterLabel {
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

.eventtiaFooterLabel {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: bold;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .eventCommanderTitle {
    text-align: center;
  }

  .eventCommanderSubtitle {
    text-align: center;
    display: block;
  }

  .timeContainer {
    padding-top: 10%;
    justify-content: center;
  }

  .timeIcon {
    margin: 0;
    margin-right: 3%;
  }

  .registeredAttendeesCardContainer {
    margin-top: 10% !important;
    margin-bottom: 8% !important;
  }

  .attendeesOnlineCardContainer {
    margin-bottom: 8% !important;
  }

  .checkInAttendeesCardContainer {
    margin-bottom: 10% !important;
  }

  .areaChart {
    margin-top: 3% !important;
  }

  .commentCard {
    margin-top: 6% !important;
  }

  .liveActivities {
    margin-top: 6% !important;
  }

  .circularChartCard {
    margin-top: 6% !important;
  }

  .eventCommanderLoaderContainer {
    padding-top: 40%;
  }

  .poweredByEventtiaFooter {
    padding-top: 5%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .eventCommanderSubtitle {
    display: block;
  }

  .timeContainer {
    padding-top: 2%;
  }

  .timeIcon {
    margin: 0;
    margin-right: 3%;
  }

  .registeredAttendeesCardContainer {
    margin-bottom: 4% !important;
  }

  .attendeesOnlineCardContainer {
    margin-left: 10% !important;
    margin-bottom: 4% !important;
  }

  .checkInAttendeesCardContainer {
    margin-top: 2% !important;
    margin-bottom: 4% !important;
  }

  .areaChart {
    margin-top: 3% !important;
  }

  .commentCard {
    margin-top: 4% !important;
  }

  .liveActivities {
    margin-top: 4% !important;
  }

  .circularChartCard {
    margin-top: 4% !important;
  }

  .eventCommanderLoaderContainer {
    padding-top: 30%;
  }

  .poweredByEventtiaFooter {
    padding-top: 3%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .timeIcon {
    margin-right: 4%;
  }

  .registeredAttendeesCardContainer {
    margin-right: 6% !important;
    margin-bottom: 4% !important;
  }

  .attendeesOnlineCardContainer {
    margin-bottom: 4% !important;
  }

  .checkInAttendeesCardContainer {
    margin-top: 2% !important;
    margin-bottom: 4% !important;
  }

  .areaChart {
    margin-top: 4% !important;
  }

  .commentCard {
    margin-top: 4% !important;
  }

  .liveActivities {
    margin-top: 4% !important;
  }

  .circularChartCard {
    margin-top: 4% !important;
  }

  .eventCommanderLoaderContainer {
    padding-top: 10%;
  }

  .poweredByEventtiaFooter {
    padding-top: 2%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .timeIcon {
    margin-right: 4%;
  }

  .registeredAttendeesCardContainer {
    margin-right: 6% !important;
    margin-bottom: 4% !important;
  }

  .attendeesOnlineCardContainer {
    margin-bottom: 4% !important;
  }

  .checkInAttendeesCardContainer {
    margin-top: 2% !important;
    margin-bottom: 4% !important;
  }

  .areaChart {
    margin-top: 4% !important;
  }

  .commentCard {
    margin-top: 4% !important;
  }

  .circularChartCard {
    margin-top: 4% !important;
  }

  .eventCommanderLoaderContainer {
    padding-top: 20%;
  }

  .poweredByEventtiaFooter {
    padding-top: 2%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .timeIcon {
    margin-right: 4%;
  }

  .registeredAttendeesCardContainer {
    margin-right: 6% !important;
    margin-bottom: 4% !important;
  }

  .attendeesOnlineCardContainer {
    margin-bottom: 4% !important;
  }

  .checkInAttendeesCardContainer {
    margin-top: 2% !important;
    margin-bottom: 4% !important;
  }

  .areaChart {
    margin-top: 4% !important;
  }

  .commentCard {
    margin-top: 4% !important;
  }

  .circularChartCard {
    margin-top: 4% !important;
  }

  .eventCommanderLoaderContainer {
    padding-top: 20%;
  }

  .poweredByEventtiaFooter {
    padding-top: 2%;
  }
}
