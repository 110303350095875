.longCardContainer {
  width: 100%;
}

.titleContentContainer {
  display: flex;
  align-items: center;
}

.titleContentContainer {
  display: flex;
  align-items: center;
}

.avatarLabelsContainer {
  display: flex;
  align-items: center;
}

.titleCard {
  color: #736a73;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.titleButton {
  width: "100%";
  font-family: "Lato", sans-serif;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 30px;
  color: #ffffff;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarUrlLongCard {
  display: flex;
  text-decoration: none;
}

.MuiBadge-dot {
  padding: 7px !important;
  border-radius: 50% !important;
}

.avatarTitle {
  display: block;
  color: #736a73;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 12px;
}

.avatarLabel {
  display: block;
  color: #736a73;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.threeDotsContainer {
  display: flex;
  align-items: center;
}

.dateLabelContainer {
  display: flex;
  align-items: center;
}

.dateLabel {
  display: block;
  color: #736a73;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .longCardContainer {
    width: 100%;
    padding: 6%;
    padding-top: 3%;
    border-left-style: solid;
    border-left-width: 13px;
  }

  .longCardDividerContainer {
    padding-top: 3%;
  }

  .avatarMainContainer {
    padding-top: 8%;
  }

  .avatarLabel {
    padding-top: 1%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .longCardContainer {
    width: 100%;
    padding: 6%;
    padding-top: 3%;
    border-left-style: solid;
    border-left-width: 13px;
  }

  .longCardDividerContainer {
    padding-top: 3%;
  }

  .avatarMainContainer {
    padding-top: 8%;
  }

  .avatarLabel {
    padding-top: 1%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .longCardContainer {
    padding: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .titleButtonContainer {
    padding-top: 2%;
  }

  .titleButton {
    border-radius: 30px;
    padding-bottom: 0.5%;
    padding-left: 3%;
    padding-right: 3%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .longCardContainer {
    padding: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .titleButtonContainer {
    padding-top: 2%;
  }

  .titleButton {
    border-radius: 30px;
    padding-bottom: 0.5%;
    padding-left: 3%;
    padding-right: 3%;
  }
}
