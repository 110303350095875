.dotIcon {
  padding: 4%;
}

.liveActivitiesTitle {
  padding-top: 0.2%;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin: 0;
}

.activitiesContainer {
  padding-top: 2%;
}

.activityContainer {
  padding-top: 1%;
  padding-bottom: 1%;
}

.timeLabel {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  margin: 0;
}

.activityName {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  margin: 0;
}

.speakerContainer {
  padding-top: 2%;
}

.speakerLabel {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.progressBarContainer {
  padding-top: 5%;
}

.attendeesTitle {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
}

.attendeesLabel {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-align: center;
}

.arrowIconContainer {
  padding-top: 3%;
  padding-left: 1%;
}

.activityPageNumber {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 12px;
  text-align: center;
}

.noActivitiesImgContainer {
  padding: 2%;
}

.noActivitiesImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.noActivitiesMessageContainer {
  padding: 10%;
  padding-top: 0;
}

.noActivitiesMessage {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-align: center;
}


/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .liveActivitiesContainer {
    padding: 6%;
    padding-bottom: 0;
  }

  .dotIcon {
    padding-top: 3%;
    padding-right: 3%;
  }

  .activitiesContainer {
    padding-top: 8%;
  }

  .activityContainer {
    padding-top: 2%;
    padding-bottom: 4%;
  }

  .timeLabelContainer {
    margin: 0 !important;
  }

  .attendeesTitle {
    padding-top: 20%;
    text-align: left;
  }

  .attendeesLabel {
    text-align: left;
  }

  .arrowIconContainer {
    padding-top: 11%;
    padding-left: 2%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .liveActivitiesContainer {
    padding-top: 3%;
  }

  .dotIcon {
    padding-top: 1%;
    padding-right: 2%;
  }

  .timeLabelContainer {
    padding: 5%;
    padding-top: 0;
    padding-right: 0;
  }

  .attendeesTitle {
    padding-top: 13%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .liveActivitiesContainer {
    padding-top: 3%;
  }

  .dotIcon {
    padding-top: 1%;
    padding-right: 2%;
  }

  .timeLabelContainer {
    padding: 5%;
    padding-top: 0;
    padding-right: 0;
  }

  .attendeesTitle {
    padding-top: 13%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .liveActivitiesContainer {
    padding-top: 3%;
  }

  .dotIcon {
    padding-top: 1%;
    padding-right: 2%;
  }

  .timeLabelContainer {
    padding: 5%;
    padding-top: 0;
    padding-right: 0;
  }

  .attendeesTitle {
    padding-top: 13%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .liveActivitiesContainer {
    padding-top: 3%;
  }

  .dotIcon {
    padding-top: 1%;
    padding-right: 2%;
  }

  .timeLabelContainer {
    padding: 5%;
    padding-top: 0;
    padding-right: 0;
  }

  .attendeesTitle {
    padding-top: 13%;
  }
}
