.menuButton {
  font-family: "Lato", sans-serif;
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
  font-size: 14px;
}

.unselectedButton {
  color: #736a73;
}

.selectedButton {
  color: #851486;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-width: 5px;
}

.dotLabelButtonMenu {
  font-family: "Lato", sans-serif;
  color: #ffffff;
  background-color: #851486;
  font-size: 14px;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 10px;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .buttonMenuMainContainer {
    justify-content: center;
    padding-top: 5%;
  }

  .menuButton {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 4.8%;
  }

  .unselectedButton {
    padding-bottom: 6%;
  }

  .selectedButton {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .buttonMenuMainContainer {
    padding-top: 2%;
  }

  .menuButton {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1.8%;
  }

  .unselectedButton {
    padding-bottom: 3%;
  }

  .selectedButton {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .menuButton {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1%;
  }

  .unselectedButton {
    padding-bottom: 1.3%;
  }

  .selectedButton {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .menuButton {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1%;
  }

  .unselectedButton {
    padding-bottom: 1.3%;
  }

  .selectedButton {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .menuButton {
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1%;
  }

  .unselectedButton {
    padding-bottom: 1.3%;
  }

  .selectedButton {
    padding-left: 10px;
    padding-right: 10px;
  }
}
