.circularChartCardTitle {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin: 0;
}

.chartLabelsContainer {
  height: 100%;
  padding-top: 10%;
}

.chartDotIcon {
  padding-top: 2%;
}

.dotLabel {
  font-family: "Lato", sans-serif;
  margin-left: 10%;
  font-size: 12px;
}

.statsContainer {
  padding-top: 4%;
}

.statsTitle {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  padding-left: 23%;
  padding-right: 23%;
  font-size: 28px;
  margin: 0;
}

.statsLabel {
  text-align: center;
  font-family: "Lato", sans-serif;
  padding-left: 23%;
  padding-right: 23%;
  font-size: 12px;
  margin: 0;
}

.footerLabel {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-align: right;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .circularChartCardContainer {
    padding: 4%;
  }

  .chartDotIconContainer {
    justify-content: center;
  }

  .chartContainer {
    padding-top: 10%;
    justify-content: center;
    align-content: center;
  }

  .footerLabelContainer {
    padding-top: 17%;
  }

  .footerLabel {
    display: block;
    padding-right: 5%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .circularChartCardContainer {
    padding: 4%;
  }

  .chartDotIconContainer {
    padding-top: 10%;
  }

  .chartContainer {
    padding-top: 4%;
  }

  .dividerContainer {
    padding-top: 7%;
    padding-bottom: 11%;
  }

  .statsTitleContainer {
    padding-top: 17%;
  }

  .footerLabelContainer {
    padding-top: 17%;
  }

  .footerLabel {
    padding-left: 35%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .circularChartCardContainer {
    padding: 4%;
  }

  .chartDotIconContainer {
    padding-top: 10%;
  }

  .chartContainer {
    padding-top: 4%;
  }

  .dividerContainer {
    padding-top: 7%;
    padding-bottom: 11%;
  }

  .statsTitleContainer {
    padding-top: 17%;
  }

  .footerLabelContainer {
    padding-top: 17%;
  }

  .footerLabel {
    padding-left: 12%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .circularChartCardContainer {
    padding: 4%;
  }

  .chartDotIconContainer {
    padding-top: 10%;
  }

  .chartContainer {
    padding-top: 4%;
  }

  .dividerContainer {
    padding-top: 7%;
    padding-bottom: 11%;
  }

  .statsTitleContainer {
    padding-top: 17%;
  }

  .footerLabelContainer {
    padding-top: 17%;
  }

  .footerLabel {
    padding-left: 10%;
    cursor: pointer;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .circularChartCardContainer {
    padding: 4%;
  }

  .chartDotIconContainer {
    padding-top: 10%;
  }

  .chartContainer {
    padding-top: 4%;
  }

  .dividerContainer {
    padding-top: 7%;
    padding-bottom: 11%;
  }

  .statsTitleContainer {
    padding-top: 17%;
  }

  .footerLabelContainer {
    padding-top: 17%;
  }

  .footerLabel {
    padding-left: 25%;
    cursor: pointer;
  }
}
