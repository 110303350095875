.commentCardTitle {
  font-family: "Lato", sans-serif;
  margin: 0;
  font-size: 16px;
}

.commentCardLabel {
  font-family: "Lato", sans-serif;
  text-align: left;
  opacity: 60%;
  font-size: 12px;
}

.buttonContainer {
  justify-content: space-between;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .titleContainer {
    padding-top: 1%;
  }

  .commentCardTitle {
    padding-top: 5%;
    padding-left: 10%;
  }

  .commentCardLabel {
    padding-left: 10%;
  }

  .textFieldContainer {
    padding: 8%;
    padding-top: 12%;
  }

  .raddioButtons {
    padding: 8%;
    padding-top: 0;
    padding-bottom: 5%;
  }

  .submitButton {
    padding: 8%;
    padding-top: 0;
    padding-bottom: 5%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .titleContainer {
    padding-top: 1%;
  }

  .commentCardTitle {
    padding-top: 2%;
    padding-left: 4%;
  }

  .commentCardLabel {
    padding-left: 4%;
  }

  .textFieldContainer {
    padding: 4%;
    padding-top: 4%;
    padding-bottom: 0;
  }

  .buttonContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .raddioButtons {
    padding-left: 4%;
  }

  .submitButton {
    padding-right: 4%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .titleContainer {
    padding-top: 1%;
  }

  .commentCardTitle {
    padding-top: 2%;
    padding-left: 5%;
  }

  .commentCardLabel {
    padding-top: 0.3%;
    padding-left: 4.5%;
  }

  .textFieldContainer {
    padding: 5%;
    padding-top: 4%;
    padding-bottom: 0;
  }

  .buttonContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .raddioButtons {
    padding-left: 5%;
  }

  .submitButton {
    padding-right: 5%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .titleContainer {
    padding-top: 1%;
  }

  .commentCardTitle {
    padding-top: 2%;
    padding-left: 5%;
  }

  .commentCardLabel {
    padding-top: 0.3%;
    padding-left: 4.5%;
  }

  .textFieldContainer {
    padding: 5%;
    padding-top: 4%;
    padding-bottom: 0;
  }

  .buttonContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .raddioButtons {
    padding-left: 5%;
  }

  .submitButton {
    padding-right: 5%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .titleContainer {
    padding-top: 1%;
  }

  .commentCardTitle {
    padding-top: 2%;
    padding-left: 5%;
  }

  .commentCardLabel {
    padding-top: 0.3%;
    padding-left: 4.5%;
  }

  .textFieldContainer {
    padding: 5%;
    padding-top: 4%;
    padding-bottom: 0;
  }

  .buttonContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .raddioButtons {
    padding-left: 5%;
  }

  .submitButton {
    padding-right: 5%;
  }
}
