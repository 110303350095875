.meetingsLiveTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin: 0;
}

.meetingsLiveSubtitle {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.meetingsLiveTime {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.meetingsLiveNowLabel {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.meetingsLiveNowTime {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 800;
  display: flex;
  align-items: flex-end;
}

.noMeetingsContentContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.noMeetingsLiveImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.noMeetingsLiveMessage {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-align: center;
}

.meetingsCommanderLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .meetingsLiveTitle {
    text-align: center;
    font-size: 23px;
  }

  .meetingsLiveSubtitle {
    text-align: center;
    display: block;
  }

  .timeContainer {
    padding-top: 10%;
    justify-content: center;
  }

  .timeIcon {
    margin: 0;
    margin-right: 3%;
  }

  .meetingsDividerContainer {
    padding-top: 4%;
  }

  .meetingsLiveContentContainer {
    padding-top: 4%;
  }

  .meetingsLiveNowTimeLabelContainer {
    justify-content: center;
  }

  .meetingsLiveNowLabel {
    font-size: 14px;
  }

  .meetingsLiveNowTime {
    font-size: 14px;
  }

  .noMeetingsContentContainer {
    padding: 10%;
  }

  .noMeetingsLiveImgContainer {
    padding: 2%;
  }

  .noMeetingsLiveMessageContainer {
    padding: 2%;
    padding-top: 0;
  }

  .meetingsCommanderLoaderContainer {
    padding-top: 40%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .timeContainer {
    padding-top: 2%;
  }

  .timeIcon {
    margin: 0;
    margin-right: 3%;
  }

  .noMeetingsContentContainer {
    padding: 10%;
  }

  .noMeetingsLiveImgContainer {
    padding: 2%;
  }

  .noMeetingsLiveMessageContainer {
    padding: 2%;
    padding-top: 0;
  }

  .meetingsCommanderLoaderContainer {
    padding-top: 30%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .arrowBackContainer {
    padding-top: 0.7%;
  }

  .timeIcon {
    margin-right: 4%;
  }

  .noMeetingsContentContainer {
    padding: 10%;
  }

  .noMeetingsLiveImgContainer {
    padding: 2%;
  }

  .noMeetingsLiveMessageContainer {
    padding: 2%;
    padding-top: 0;
  }

  .meetingsCommanderLoaderContainer {
    padding-top: 20%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .arrowBackContainer {
    padding-top: 0.7%;
  }

  .timeIcon {
    margin-right: 4%;
  }

  .noMeetingsContentContainer {
    padding: 10%;
  }

  .noMeetingsLiveImgContainer {
    padding: 2%;
  }

  .noMeetingsLiveMessageContainer {
    padding: 2%;
    padding-top: 0;
  }

  .meetingsCommanderLoaderContainer {
    padding-top: 20%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .arrowBackContainer {
    padding-top: 0.7%;
  }

  .timeIcon {
    margin-right: 4%;
  }

  .noMeetingsContentContainer {
    padding: 10%;
  }

  .noMeetingsLiveImgContainer {
    padding: 2%;
  }

  .noMeetingsLiveMessageContainer {
    padding: 2%;
    padding-top: 0;
  }

  .meetingsCommanderLoaderContainer {
    padding-top: 20%;
  }
}
