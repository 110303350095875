.dialogHeaderContainer {
  box-shadow: 0px 3px 6px #00000012;
}

.dialogTitleContainer {
  display: flex;
  align-items: center;
}

.dialogTitle {
  font-family: "Lato", sans-serif;
  font-size: 21px;
  font-weight: bold;
}

.dialogSubtitle {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.dialogText {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.meetingsLiveTime {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.avatarLabelsContainer {
  display: flex;
  align-items: center;
}

/* ----------- Phone ------------ */
@media (max-width: 600px) {
  .MuiDialog-paperWidthSm {
    max-width: 352px !important;
  }

  .dialogTitle {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dialogIconContainer {
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .dialogSubtitleContainer {
    padding-top: 6%;
  }

  .dialogTextContainer {
    padding-top: 6%;
  }

  .dialogButtonsContainer {
    padding-top: 10%;
  }

  .dialogCancelButtonContainer {
    padding-bottom: 4%;
  }
}

/* ----------- Tablet ------------ */
@media (min-width: 601px) and (max-width: 768px) {
  .MuiDialog-paperWidthSm {
    max-width: 352px !important;
  }

  .dialogTitle {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dialogIconContainer {
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .dialogSubtitleContainer {
    padding-top: 6%;
  }

  .dialogTextContainer {
    padding-top: 6%;
  }

  .dialogButtonsContainer {
    padding-top: 10%;
  }

  .dialogCancelButtonContainer {
    padding-bottom: 4%;
  }
}

/* ----------- Tablet horizontal ------------ */
@media (min-width: 769px) and (max-width: 1023px) {
  .MuiDialog-paperWidthSm {
    max-width: 352px !important;
  }

  .dialogTitle {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dialogIconContainer {
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .dialogSubtitleContainer {
    padding-top: 6%;
  }

  .dialogTextContainer {
    padding-top: 6%;
  }

  .dialogButtonsContainer {
    padding-top: 10%;
  }

  .dialogCancelButtonContainer {
    padding-bottom: 4%;
  }
}

/* ----------- Laptop ------------ */
@media (min-width: 1024px) and (max-width: 1200px) {
  .MuiDialog-paperWidthSm {
    max-width: 352px !important;
  }

  .dialogTitle {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dialogIconContainer {
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .dialogSubtitleContainer {
    padding-top: 6%;
  }

  .dialogTextContainer {
    padding-top: 6%;
  }

  .dialogButtonsContainer {
    padding-top: 10%;
  }

  .dialogCancelButtonContainer {
    padding-bottom: 4%;
  }
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .MuiDialog-paperWidthSm {
    max-width: 352px !important;
  }

  .dialogTitle {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dialogIconContainer {
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .dialogSubtitleContainer {
    padding-top: 6%;
  }

  .dialogTextContainer {
    padding-top: 6%;
  }

  .dialogButtonsContainer {
    padding-top: 10%;
  }

  .dialogCancelButtonContainer {
    padding-bottom: 4%;
  }
}
