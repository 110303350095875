.loginViewContainer{
    padding-top: 10%;
}

.loginContainer {
  height: 100%;
}

.eventtiaImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* ----------- Desktop ------------ */
@media (min-width: 1201px) {
  .loginContainer {
    padding: 5%;
  }

  .imgContainer {
    margin-bottom: 5% !important;
  }

  .eventtiaImg {
    width: 50%;
  }
}
